import { datadogRum } from '@datadog/browser-rum'
import { getAppGlobals } from '~/global-data'

interface DatadogConfig {
  applicationId?: string
  clientToken?: string
  service?: string
  env?: string
  sessionSampleRate: number
  sessionReplaySampleRate: number
  traceSampleRate: number
  allowedTracingOrigins: Array<string | RegExp>
}

export const initializeDataDog = () => {
  const { applicationId, clientToken, service, env, ...config } = getConfig()
  if (applicationId && clientToken && service && env) {
    datadogRum.init({
      applicationId,
      clientToken,
      service,
      env,
      site: 'datadoghq.com',
      defaultPrivacyLevel: 'mask',
      ...config,
    })
  }
}

const getConfig = (): DatadogConfig => {
  const {
    DATADOG_APPLICATION_ID: applicationId,
    DATADOG_CLIENT_TOKEN: clientToken,
    DATADOG_SERVICE: service,
    DATADOG_SESSION_REPLAY_SAMPLE_RATE: sessionSampleRate,
    DATADOG_SESSION_SAMPLE_RATE: sessionReplaySampleRate,
    DATADOG_TRACE_SAMPLE_RATE: traceSampleRate,
    DATADOG_ENV: env,
    HOST: host,
  } = getAppGlobals()

  return {
    applicationId,
    clientToken,
    service,
    env,
    sessionSampleRate: maybeParseInt(sessionSampleRate) || 10,
    sessionReplaySampleRate: maybeParseInt(sessionReplaySampleRate) || 10,
    traceSampleRate: maybeParseInt(traceSampleRate) || 10,
    allowedTracingOrigins: [
      /https:\/\/.*\.circlemedical\.com/,
      ...(host ? [host] : []),
    ],
  }
}

const maybeParseInt = (value: string | undefined): number | undefined =>
  value ? parseInt(value) : undefined
