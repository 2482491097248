// Datadog Init must be imported before any other imports, so it can patch the global objects
import { initializeDataDog } from '~/lib/monitoring'
initializeDataDog()

import * as Sentry from '@sentry/remix'
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { getAppGlobals } from '~/global-data'

const { APP_ENV, SENTRY_DSN } = getAppGlobals()

const isProd = APP_ENV === 'production'
Sentry.init({
  dsn: SENTRY_DSN,
  environment: APP_ENV,
  tracesSampleRate: isProd ? 0.1 : 0,
  replaysSessionSampleRate: isProd ? 0.1 : 0,
  replaysOnErrorSampleRate: isProd ? 0.5 : 0,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      mask: ['[data-masked]', 'input'],
      block: ['[data-blocked]'],
      networkDetailAllowUrls: [
        /.*\.cireclemedical\.com/,
        /.*\.rudderstack\.com/,
      ],
    }),
    Sentry.feedbackIntegration({
      autoInject: false,
      colorScheme: 'light',
      messagePlaceholder:
        'Submit technical bugs here. If you have a patient care or health issue, please contact the PCA team via the HIPAA-compliant chat in the Circle Medical app.',
      showEmail: false,
      showName: false,
      enableScreenshot: true,
    }),
  ],
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
